<template>
  <auth-content>
    <el-header slot="header">
      <el-header-action v-if="isWorldOwner" size="xs" slot="actions" icon="plus3" :label="$t('user.list.invite_users')" @click.native="openInviteModal()"/>
    </el-header>

    <el-filters :filters="filters"/>

    <div v-for="(user, index) in usersFiltered" v-if="user">
      <el-user-teaser
        :user="user"
        :changeOwnerStatus="changeOwnerStatus"
        :resendUserPassword="resendUserPassword"
        :confirmUserRemove="confirmUserRemove"
        :relaunchInvitation="relaunchInvitation"
        :removeInvitation="removeInvitation"
       />
    </div>

    <el-modal size="md" :title="$t('user.list.invite_users')" id="user-world-invite">
      <form @submit.prevent="sendInvitation()">
        <div class="form-group">
          <TextareaAutosize
            ref="invite_emails_textarea"
            v-model="invite_emails"
            required="required"
            class="form-control"
            :placeholder="$t('user.list.invite_users')"
            id="invite_emails"
          />
          <small class="text-grey">{{ $t('user.list.invite_users_help') }}</small>
        </div>
        <div class="text-center">
          <el-alert v-tooltip="$t('subscription.explanation')" type="success" v-if="worldHasSubscription && world.plan.free_invitations_left < 1">
            {{ $t('user.list.invitation_confirmation_text') }}<br/>
            <span class="border-bottom cursor-pointer" @click="closeAndGoToSubscriptionPage()">
              {{ $t('user.list.invitation_confirmation_pricing_link') }}
            </span>
          </el-alert>
          <button v-if="!invite_loading" type="submit" class="btn btn-success text-white">{{ $t('common.send') }}</button>
          <div v-if="invite_loading" class="text-primary pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>

    <el-modal size="sm" :title="$t('user.list.remove_user')" id="user-world-remove">
      <form @submit.prevent="removeUser()">
        <div v-if="!remove_loading" class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_user_confirm">
            {{ $t('user.list.remove_user_world_confirmation_text') }}
          </label>
        </div>
        <div class="text-center">
          <button v-if="!remove_loading" type="submit" class="btn btn-danger text-white">{{ $t('common.submit') }}</button>
          <div v-if="remove_loading" class="text-primary pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>
  </auth-content>
</template>


<script>

import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '../../store'
import {
  AUTH_PASSWORD_RESET,
  WORLD_USERS_REQUEST,
  WORLD_INVITATIONS_REQUEST,
  WORLD_INVITATION_SEND,
  WORLD_OWNER_STATUS_CHANGE,
  WORLD_USER_REMOVE,
  WORLD_INVITATION_REMOVE,
} from '../../store/mutations-types'

export default {
  name: 'WorldUsersList',
  tag: 'world-users-list',
  data () {
    return {
      searchValue: '',
      remove_user_id: null,
      remove_user_confirm: false,
      invite_emails: '',
      remove_loading: false,
      invite_loading: false,
      invitations: false,
      users: false,
      loading: false,
      columns: {
        first_name: this.$t('form.user.first_name'),
        last_name: this.$t('form.user.last_name'),
        email: this.$t('form.user.email'),
        phone_number: this.$t('form.user.phone_number'),
        position: this.$t('form.user.position')
      },
      filters: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_USERS_REQUEST, to.params.world).then(users => {
      next(vm => {
        vm.users = _.sortBy({...users}, (user) => { return !user.pivot.owner })
      })
    })
  },
  created () {
    // Load data after page init to speed up the page rendering
    this.loadInvitations()
  },
  methods: {
    openInviteModal() {
      this.openModal('user-world-invite')
      setTimeout(() => {
        this.$refs.invite_emails_textarea.$el.focus()
      }, 200)
    },
    loadUsers () {
      this.$store.dispatch('world/' + WORLD_USERS_REQUEST, this.$route.params.world).then(users => {
        this.users = users
        this.loadFilters()
      })
    },
    loadInvitations () {
      if (this.isWorldOwner) {
        this.$store.dispatch('world/' + WORLD_INVITATIONS_REQUEST, this.$route.params.world).then(invitations => {
          this.invitations = invitations
          this.loadFilters()
        })
      } else {
        this.loadFilters()
      }
    },
    loadFilters () {
      let users = {...this.formatUsers()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('form.user.status'),
          key: 'status',
          options: this.getFilterOptions(users, 'status'),
        },
        {
          title: this.$t('form.user.tribes'),
          key: 'tribes',
          options: this.getFilterOptions(users, 'tribes')
        },
        {
          title: this.$t('form.user.expertise'),
          key: 'expertise',
          options: this.getFilterOptions(users, 'expertise')
        },
        {
          title: this.$t('form.user.position'),
          key: 'position',
          options: this.getFilterOptions(users, 'position')
        }
      ])
    },
    sendInvitation () {
      this.invite_loading = true
      this.$store.dispatch('world/' + WORLD_INVITATION_SEND, {
        worldId: this.$route.params.world,
        emails: this.invite_emails
      }).then(data => {
        this.invite_loading = false
        this.notifSuccess(data)
        this.loadInvitations()
        this.closeModal('user-world-invite')
        this.invite_emails = ''
      })
      .catch(error => {
        this.invite_loading = false
        this.notifError(error)
      })
    },
    relaunchInvitation (email) {
      this.$store.dispatch('world/' + WORLD_INVITATION_SEND, {
        worldId: this.$route.params.world,
        emails: email
      }).then(data => {
        this.notifSuccess(data)
        this.loadInvitations()
      })
      .catch(error => {
        this.notifError(error)
      })
    },
    changeOwnerStatus (user_id, status) {
      this.$store.dispatch('world/' + WORLD_OWNER_STATUS_CHANGE, {
        worldId: this.$route.params.world,
        userId: user_id,
        status: status
      }).then(data => {
        this.notifSuccess(data)
        this.loadUsers()
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    resendUserPassword (user_email, status) {
      this.$store.dispatch('auth/' + AUTH_PASSWORD_RESET, user_email).then(data => {
        this.notifSuccess(this.$t('user.list.resend_password_confirm'))
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    removeInvitation (invitation_id) {
      this.$store.dispatch('world_invitation/' + WORLD_INVITATION_REMOVE, {
        worldId: this.$route.params.world,
        invitationId: invitation_id
      }).then(data => {
        this.notifSuccess(data)
        this.loadInvitations()
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    confirmUserRemove (userId) {
      this.remove_user_confirm = false
      this.remove_user_id = userId
      this.openModal('user-world-remove');
    },
    removeUser() {
      if (this.remove_user_confirm) {
        this.remove_loading = true
        this.$store.dispatch('world/' + WORLD_USER_REMOVE, {
          worldId: this.$route.params.world,
          userId: this.remove_user_id
        }).then(data => {
          this.remove_loading = false
          this.notifSuccess(data)
          this.loadUsers()
          this.closeModal('user-world-remove')
          this.remove_user_id = null
          this.remove_user_confirm = false
        })
        .catch(error => {
          this.remove_loading = false
          this.notifError(error)
        })
      }
    },
    formatUsers () {
      let users = []

      if (this.users) {
        // Get users
        _.map(this.users, (elem) => {
          let user = {
            id: elem.id,
            link: this.$router.resolve({name: 'world.users.profile', params: {world : this.$route.params.world, user : elem.id}}).href,
            avatar: elem.avatar.thumb,
            email: elem.email,
            first_name: elem.first_name,
            last_name: elem.last_name,
            phone_number: elem.phone_number,
            phone_search: elem.phone_number ? elem.phone_number.replace(elem.phone_number.split(' ')[0], '0').replace(/\s/g,'') : '',
            expertise: elem.metadata && elem.metadata.expertise ? elem.metadata.expertise.split(',') : [],
            tribes: elem.tribes ? elem.tribes.map(tribe => tribe.name) : [],
            position: elem.position,
            birthday: elem.birthday,
            owner: elem.pivot.owner == true,
            status: elem.pivot.owner == true ? this.$t('user.list.owner') : '',
          }
          users.push(user);
        })
      }

      if (this.invitations) {
        // Get invits
        _.map(this.invitations, (elem) => {
          users.push({
            invitation_id: elem.id,
            avatar: elem.avatar.thumb,
            email: elem.email,
            first_name: null,
            last_name: null,
            phone_number: null,
            position: null,
            birthday: null,
            invitation: true,
            status: this.$t('user.list.invited')
          })
        })
      }
      return users
    },
    closeAndGoToSubscriptionPage () {
      this.closeModal('user-world-invite')
      this.$router.push('/world/' + this.$route.params.world + '/subscription')
    }
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    worldHasSubscription () {
      return this.$store.getters['auth/worldHasSubscription']
    },
    currentUserId () {
      return this.$store.getters['auth/getId']
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    usersFormatted () {
      return this.formatUsers()
    },
    usersFiltered () {

      let searchValue = this.$store.state.global.sidebarSearch
      if (searchValue === '') {
        return this.applyDataFilters(this.usersFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.usersFormatted, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "email",
          "first_name",
          "last_name",
          "phone_number",
          "phone_search",
          "position",
        ]
      }).search(searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
img.preview {
  object-fit: cover;
  width: 35px;
  height: 35px;
}
/deep/ .avatar img.small {
  height: 40px;
  width: 40px;
}
.button-icon-action {
  display: inline-block;
  border-radius: 100px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  text-align:center;
  background: #eee;
  margin-left:6px;
  cursor: pointer;
  transition: .1s all ease;
  &:hover {
    background: #333;
    i {
      color: #eee;
    }
  }
}
</style>
