import { render, staticRenderFns } from "./TribesList.vue?vue&type=template&id=678722b7&scoped=true&"
import script from "./TribesList.vue?vue&type=script&lang=js&"
export * from "./TribesList.vue?vue&type=script&lang=js&"
import style0 from "./TribesList.vue?vue&type=style&index=0&id=678722b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "678722b7",
  null
  
)

export default component.exports