<template>
  <auth-content>
    <el-header slot="header" />

    <form @submit.prevent="editPermissions" v-if="permissions">

      <el-alert type="info">
        {{ $t('form.world.permissions_owner_msg') }}
      </el-alert>
      <div v-for="(module, moduleKey) in permissions.modules" v-if="world.modules && world.modules[moduleKey] && world.modules[moduleKey] == true">
        <div class="card card-content">
          <div class="card-header py-3">
            <div class="d-md-flex justify-content-between align-items-start">
              <h5 class="mb-0 cursor-pointer d-md-flex align-items-center" @click="moduleKey == expanded ? expanded = null : expanded = moduleKey">
                <i :class="`pt-1 mr-2 small mr-1 text-primary icon-${module.icon}`"/>
                <el-card-title class="pr-2" :title="$te(`modules.${moduleKey}.title`) ? $t(`modules.${moduleKey}.title`) : moduleKey"/>
                <div>
                  <span class="badge badge-light mr-2" v-for="(page, pageKey, index) in module.pages" v-if="(page.permissions != false) && $t(`modules.${moduleKey}.${pageKey}.title`) != $t(`modules.${moduleKey}.title`)">
                    {{ $t(`modules.${moduleKey}.${pageKey}.title`) }}
                  </span>
                </div>
              </h5>
              <div v-if="expanded != moduleKey">
                <span class="btn btn-sm btn-secondary" @click="expanded = moduleKey">
                  {{ $t('permissions.common.change_permissions') }}
                </span>
              </div>
            </div>
            <div class="row no-gutters" v-if="expanded == moduleKey">
              <div class="col-md-6 text-center d-none d-md-flex" />
              <div class="col-md-6 text-center d-none d-md-flex">
                <div class="row w-100 no-gutters">
                  <div class="col pt-1" v-for="(permission, permissionKey) in module.permissions">
                    <i :class="'mr-1 icon-' + permission.icon" />
                    {{ $te('permissions.' + permissionKey) ?
                         $t('permissions.' + permissionKey) :
                         $te(`modules.${moduleKey}.permissions.${permissionKey}`) ?
                           $t(`modules.${moduleKey}.permissions.${permissionKey}`) :
                           permissionKey
                    }}
                    <div class="small text-grey">
                      <span v-if="permissionsByKey && permissionsByKey[moduleKey] && permissionsByKey[moduleKey][permissionKey] && permissionsByKey[moduleKey][permissionKey].length == permissions.tribes.length" class="cursor-pointer" @click="checkAllTribes(moduleKey, permissionKey, false)">{{ $t('form.world.permissions_uncheck_all') }}</span>
                      <span v-else class="cursor-pointer" @click="checkAllTribes(moduleKey, permissionKey)">{{ $t('form.world.permissions_check_all') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body border-bottom border-light p-0" v-if="expanded == moduleKey">
            <div class="container-fluid m-0">
              <div class="row p-2 row-permission" v-for="(tribe, tribeKey) in permissions.tribes" :class="{ 'bg-light': !(tribeKey % 2) }">
                <div class="col-md-6 d-flex align-items-center">
                  <a class="mb-1 mb-md-0 d-inline-block text-dark mb-2 mb-md-0 font-weight-bold" v-tooltip="$t('common.open_new_tab')" :href="`/world/${$route.params.world}/tribes/${tribe.id}`" target="_new">
                    <el-avatar v-if="tribe.avatar && tribe.avatar.thumb" :src="tribe.avatar.thumb"/>
                    <el-avatar v-else-if="tribe.slug" :src="require('@/assets/images/categories/' + tribe.slug + '.svg')"/>
                    <span class="px-1">
                      {{ tribe.name }}
                    </span>
                  </a>
                </div>
                <div class="col-md-6 text-md-center">
                  <div class="row">
                    <div class="col" v-for="(permission, permissionKey) in module.permissions">
                      <div class="cursor-pointer" @click="togglePermission(moduleKey, tribe.id, permissionKey)">
                        <i v-if="
                          permissions.permissions[moduleKey] &&
                          permissions.permissions[moduleKey][tribe.id] &&
                          permissions.permissions[moduleKey][tribe.id][permissionKey]
                        " class="icon-checkbox-checked2 mr-1 text-success" />
                        <i v-if="
                          !permissions.permissions[moduleKey] ||
                          !permissions.permissions[moduleKey][tribe.id] ||
                          !permissions.permissions[moduleKey][tribe.id][permissionKey]
                        " class="icon-checkbox-unchecked2 mr-1 text-grey" />
                        <span class="d-md-none">
                          {{ $te('permissions.' + permissionKey) ?
                               $t('permissions.' + permissionKey) :
                               $te(`modules.${moduleKey}.permissions.${permissionKey}`) ?
                                 $t(`modules.${moduleKey}.permissions.${permissionKey}`) :
                                 permissionKey
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="mt-1" v-for="(permission, permissionKey, indexpermission) in module.permissions">
              <span class="mr-2 d-inline-block font-weight-bold">
                {{ $te('permissions.' + permissionKey) ?
                     $t('permissions.' + permissionKey) :
                     $te(`modules.${moduleKey}.permissions.${permissionKey}`) ?
                       $t(`modules.${moduleKey}.permissions.${permissionKey}`) :
                       permissionKey
                }}&nbsp;: </span>
              <span v-if="permissionsByKey && permissionsByKey[moduleKey] && permissionsByKey[moduleKey][permissionKey] && permissionsByKey[moduleKey][permissionKey].length == permissions.tribes.length" class="text-success font-weight-bold">
                {{ $t('form.world.permissions_all_tribes') }}
              </span>
              <span v-else-if="permissionsByKey && permissionsByKey[moduleKey] && permissionsByKey[moduleKey][permissionKey] && permissionsByKey[moduleKey][permissionKey].length > 0">
                <span v-for="(tribeKey, index) in permissionsByKey[moduleKey][permissionKey]" :key="index">
                  <span class="d-inline-block mr-2 mb-2">
                    <el-avatar v-if="tribesByKey[tribeKey].avatar && tribesByKey[tribeKey].avatar.thumb" :src="tribesByKey[tribeKey].avatar.thumb" v-tooltip="tribesByKey[tribeKey].name" />
                    <el-avatar v-else-if="tribesByKey[tribeKey].slug" :src="require('@/assets/images/categories/' + tribesByKey[tribeKey].slug + '.svg')" v-tooltip="tribesByKey[tribeKey].name" />
                  </span>
                </span>
              </span>
              <span v-else class="text-grey">
                {{ $t('form.world.permissions_no_tribe') }}
              </span>
            </div>
          </div>
          <div class="card-footer pt-2" v-if="expanded == moduleKey">
            <button v-if="!loading" class="btn btn-lg btn-success text-white">{{ $t('common.save') }}</button>
            <div v-if="loading" class="bg-white p-2">
              <span class="text-success"><loader></loader></span>
            </div>
          </div>
        </div>


      </div>
    </form>

  </auth-content>
</template>

<script>

import _ from 'lodash'
import store from '@/store'
import { WORLD_PERMISSIONS_REQUEST, WORLD_PERMISSIONS_UPDATE } from '@/store/mutations-types'


export default {
  name: 'PermissionsForm',
  data () {
    return {
      expanded: null,
      loading: false,
      permissions: false,
    }
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_PERMISSIONS_REQUEST, to.params.world).then(permissions => {
      next(vm => {
        vm.permissions = {...permissions}
      })
    })
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld'];
    },
    tribesByKey () {
      return _.keyBy(this.permissions.tribes, 'id')
    },
    permissionsByKey () {
      let permissions = {}
      _.forEach(this.permissions.permissions, (tribesPermissions, moduleKey) => {
        _.forEach(tribesPermissions, (tribesPermission, tribeKey) => {
          _.forEach(tribesPermission, (permissionValue, permissionKey) => {
            if (permissionValue && permissionValue === true) {
              if (!permissions[moduleKey]) {
                permissions[moduleKey] = {}
              }
              if (!permissions[moduleKey][permissionKey]) {
                permissions[moduleKey][permissionKey] = []
              }
              permissions[moduleKey][permissionKey].push(tribeKey)
            }
          })
        })
      })

      return permissions
    },
  },
  methods: {
    checkAllTribes (moduleKey, permissionKey, check = true) {
      if (Array.isArray(this.permissions.permissions) && this.permissions.permissions.length == 0) {
        this.$set(this.permissions, 'permissions', {})
      }
      if (!this.permissions.permissions[moduleKey]) {
        this.$set(this.permissions.permissions, moduleKey, {})
      }
      _.forEach(this.permissions.tribes, tribe => {
        if (!this.permissions.permissions[moduleKey][tribe.id]) {
          this.$set(this.permissions.permissions[moduleKey], tribe.id, {})
        }
        this.permissions.permissions[moduleKey][tribe.id] = Object.assign({}, this.permissions.permissions[moduleKey][tribe.id],
          {
            [permissionKey]: check
          }
        )
      })
    },
    togglePermission(moduleAlias, tribeId, permissionKey) {
      if (
        this.permissions.permissions[moduleAlias] &&
        this.permissions.permissions[moduleAlias][tribeId] &&
        this.permissions.permissions[moduleAlias][tribeId][permissionKey]
      ) {
        this.permissions.permissions[moduleAlias][tribeId][permissionKey] = !this.permissions.permissions[moduleAlias][tribeId][permissionKey]
      } else if (
        this.permissions.permissions[moduleAlias] &&
        this.permissions.permissions[moduleAlias][tribeId]
      ) {
        this.permissions.permissions[moduleAlias][tribeId] = Object.assign({}, this.permissions.permissions[moduleAlias][tribeId],
          {
            [permissionKey]: true
          }
        )
      } else if (
        this.permissions.permissions[moduleAlias]
      ) {
        this.permissions.permissions[moduleAlias] = Object.assign({}, this.permissions.permissions[moduleAlias],
          {
            [tribeId]: {
              [permissionKey]: true
            }
          }
        )
      } else {
        this.permissions.permissions = Object.assign({}, this.permissions.permissions,
          {
            [moduleAlias]: {
              [tribeId]: {
                [permissionKey]: true
              }
            }
          }
        )
      }
    },
    editPermissions () {
      this.loading = true
      this.$store.dispatch('world/' + WORLD_PERMISSIONS_UPDATE, {
        worldId: this.$route.params.world,
        permissions: this.permissions.permissions
      }).then(data => {
        this.loading = false
        this.notifSuccess(this.$t('form.world.permissions_edit_success'))
        this.world = {...data}
        this.expanded = null
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.row-permission {
  &:hover {
    background: rgba(black, .05) !important;
  }
}
</style>
