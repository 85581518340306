<template>
  <auth-content>
    <el-header slot="header" :titleSuffix="name" :favorites="false">
      <div slot="left">
        <el-header-back />
      </div>
      <el-header-action v-if="isWorldOwner" slot="actions" icon="plus3" :label="$t('tribe.list.add_user')" @click.native.prevent="openModal('tribe-add-user')"></el-header-action>
    </el-header>
    <div>

      <el-filters :filters="filters"/>

      <el-table
        :total="Object.keys(users).length"
        :columns="columns"
        :data="usersFiltered"
      >
        <div slot="badge" slot-scope="{row}">
          <span v-tooltip="$t('user.list.tribe_owner')" v-if="row.owner" class="badge bg-primary rounded-circle badge-icon"><i class="icon-crown"></i></span>
          <span v-tooltip="$t('user.list.invited')" v-if="row.invitation" class="badge bg-light rounded-circle badge-icon"><i class="icon-hour-glass2"></i></span>
        </div>
        <div slot="thumb" slot-scope="{row}">
          <el-avatar v-if="row.avatar && row.avatar != ''" :src="row.avatar"></el-avatar>
          <a v-if="row.icon && row.icon != ''" class="btn bg-light rounded-round btn-icon btn-sm">
            <i :class="'text-grey icon-' + row.icon"></i>
          </a>
        </div>

        <fragment slot="actions" slot-scope="{row}">

          <el-table-action
            v-if="row.id !== currentUserId && !row.owner && userCanManageTribe($route.params.tribe)"
            @click.prevent.native="changeOwnerStatus(row.id, true)"
            icon="icon-crown"
            :title="$t('user.list.set_tribe_owner')">
          </el-table-action>
          <el-table-action
            v-if="row.id !== currentUserId && row.owner && userCanManageTribe($route.params.tribe)"
            @click.prevent.native="changeOwnerStatus(row.id, false)"
            icon="icon-crown"
            :title="$t('user.list.unset_tribe_owner')">
          </el-table-action>
          <el-table-action
            v-if="row.id !== currentUserId && userCanManageTribe($route.params.tribe)"
            @click.prevent.native="confirmUserRemove(row.id)"
            icon="icon-bin"
            :title="$t('user.list.remove_user_tribe')">
          </el-table-action>

        </fragment>
      </el-table>

    </div>

    <el-modal size="sm" :title="$t('tribe.list.add_user')" id="tribe-add-user">
      <form @submit.prevent="addUser()">
        <div class="form-group">
          <label for="invite_email" class="font-weight-bold label-required">{{ $t('tribe.list.add_user_choose') }}</label>
          <el-select :options="usersToInvite" v-model="tribe_user_invite_id" :placeholder="$t('tribe.list.add_user_choose')"></el-select>
        </div>
        <div class="text-center">
          <button v-if="!invite_loading" type="submit" class="btn btn-success text-white">{{ $t('common.send') }}</button>
          <div v-if="invite_loading" class="text-gray pt-1 pb-2"><loader /></div>
          <div v-if="isWorldOwner" @click="closeModal('tribe-add-user'); $router.push('/world/' + $route.params.world + '/users')" class="mt-2 cursor-pointer text-grey text-center">
            <small>
              {{ $t('tribe.list.user_invite_link') }}
            </small>
          </div>
        </div>
      </form>
    </el-modal>

    <el-modal size="sm" :title="$t('user.list.remove_user')" id="user-tribe-remove">
      <form @submit.prevent="removeUser()">
        <div class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_user_confirm">
            {{ $t('user.list.remove_user_tribe_confirmation_text') }}
          </label>
        </div>
        <div class="text-center">
          <button v-if="!invite_loading" type="submit" class="btn btn-danger text-white">{{ $t('common.submit') }}</button>
          <div v-if="invite_loading" class="pt-1 pb-2"><loader /></div>
        </div>
      </form>
    </el-modal>
  </auth-content>
</template>


<script>

import Fuse from 'fuse.js'
import _ from 'lodash'
import store from '../../store'
import {
  WORLD_TRIBES_DETAILS,
  WORLD_TRIBES_OWNER_STATUS_CHANGE,
  WORLD_TRIBES_USER_ADD,
  WORLD_TRIBES_USER_REMOVE,
} from '../../store/mutations-types'

export default {
  name: 'WorldTribesUsers',
  tag: 'world-tribes-users',
  data () {
    return {
      searchValue: '',
      remove_user_id: null,
      remove_user_confirm: false,
      tribe_user_invite_id: '',
      tribe_add_user_list: [],
      invite_loading: false,
      invitations: false,
      name: null,
      users: false,
      usersToInvite: false,
      loading: false,
      columns: {
        first_name: this.$t('form.user.first_name'),
        last_name: this.$t('form.user.last_name'),
        // email: this.$t('form.user.email'),
        // phone_number: this.$t('form.user.phone_number'),
        position: this.$t('form.user.position')
      },
      filters: {}
    }
  },
  mounted () {
    this.$store.commit('global/changeSidebarSearch', '') // reset search on page load
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('world/' + WORLD_TRIBES_DETAILS, {
      worldId: to.params.world,
      tribeId: to.params.tribe
    }).then(details => {
      next(vm => {
        vm.name = details.name
        vm.users = {...details.users}
        if (details.usersToInvite) {
          vm.usersToInvite = vm.formatUsersToInvite(details.usersToInvite)
        }
        vm.loadFilters()
      })
    })
  },
  created () {
  },
  methods: {
    loadUsers () {
      this.$store.dispatch('world/' + WORLD_TRIBES_DETAILS, {
        worldId: this.$route.params.world,
        tribeId: this.$route.params.tribe
      }).then(details => {
        this.name = details.name
        this.users = details.users
        if (details.usersToInvite) {
          this.usersToInvite = this.formatUsersToInvite(details.usersToInvite)
        }
        this.loadFilters()
      })
    },
    loadFilters () {
      let users = {...this.formatUsers()}
      this.filters = Object.assign({}, this.filters, [
        {
          title: this.$t('form.user.position'),
          key: 'position',
          options: this.getFilterOptions(users, 'position')
        }
      ])
    },
    addUser () {
      if (!this.tribe_user_invite_id) {
        return
      }
      this.invite_loading = true
      this.$store.dispatch('world/' + WORLD_TRIBES_USER_ADD, {
        worldId: this.$route.params.world,
        tribeId: this.$route.params.tribe,
        userId: this.tribe_user_invite_id
      }).then(data => {
        this.invite_loading = false
        this.notifSuccess(data)
        this.loadUsers()
        this.closeModal('tribe-add-user')
        this.tribe_user_invite_id = ''
      })
      .catch(error => {
        this.invite_loading = false
        this.notifError(error)
      })
    },
    changeOwnerStatus (user_id, status) {
      this.$store.dispatch('world/' + WORLD_TRIBES_OWNER_STATUS_CHANGE, {
        worldId: this.$route.params.world,
        tribeId: this.$route.params.tribe,
        userId: user_id,
        status: status
      }).then(data => {
        this.notifSuccess(data)
        this.loadUsers()
      })
      .catch(error => {
          this.notifError(error)
      })
    },
    confirmUserRemove (userId) {
      this.remove_user_confirm = false
      this.remove_user_id = userId
      this.openModal('user-tribe-remove');
    },
    removeUser() {
      if (this.remove_user_confirm) {
        this.$store.dispatch('world/' + WORLD_TRIBES_USER_REMOVE, {
          worldId: this.$route.params.world,
          tribeId: this.$route.params.tribe,
          userId: this.remove_user_id
        }).then(data => {
          this.notifSuccess(data)
          this.loadUsers()
          this.closeModal('user-tribe-remove')
          this.remove_user_id = null
          this.remove_user_confirm = false
        })
        .catch(error => {
            this.notifError(error)
        })
      }
    },
    formatUsersToInvite (users) {
      let usersToInvite = ['']
      _.map(users, (elem) => {
        usersToInvite.push({
          avatar: elem.avatar.thumb,
          value: elem.id,
          title: elem.full_name,
          detail: elem.email,
        })
      })
      return usersToInvite
    },
    formatUsers () {
      let users = []

      if (this.users) {
        // Get users
        _.map(this.users, (elem) => {
          let user = {
            id: elem.id,
            avatar: elem.avatar.thumb,
            email: elem.email,
            first_name: elem.first_name,
            last_name: elem.last_name,
            phone_number: elem.phone_number,
            phone_search: elem.phone_number ? elem.phone_number.replace(elem.phone_number.split(' ')[0], '0').replace(/\s/g,'') : '',
            position: elem.position,
            birthday: elem.birthday,
            owner: elem.pivot.owner == true,
            status: elem.pivot.owner == true ? this.$t('user.list.tribe_owner') : '',
          }
          users.push(user);
        })
      }

      return users
    }
  },
  computed: {
    currentUserId () {
      return this.$store.getters['auth/getId']
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    usersFormatted () {
      return this.formatUsers()
    },
    usersFiltered () {

      let searchValue = this.$store.state.global.sidebarSearch
      if (searchValue === '') {
        return this.applyDataFilters(this.usersFormatted, this.filters)
      }

      return new Fuse(this.applyDataFilters(this.usersFormatted, this.filters), {
        shouldSort: false,
        threshold: 0.3,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "email",
          "first_name",
          "last_name",
          "phone_number",
          "phone_search",
          "position",
        ]
      }).search(searchValue)
    }
  }
}
</script>

<style lang="scss" scoped>
img.preview {
  object-fit: cover;
  width: 35px;
  height: 35px;
}
</style>
